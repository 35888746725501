import {TaggedLogger} from "../utilities";
import FrontendApiAdapter, {OnHandlerFunction} from "./FrontendApiAdapter";
import {
    ApiRequestParams_auctionsList,
    ApiRequestParams_lotsListBrowse,
    ApiRequestParams_lotsSearch,
    ApiRequestParams_pagination,
    ApiResource_CatalogLot,
    ApiResource_LotMetal,
    ApiResource_RunningTimedLot,
    ApiResource_SiteSearchLot,
    ApiResource_WatchlistRunningLot,
    ApiResourceDetailsLevelsEnum
} from "../type_defs/api";
import {
    Auction_ModelTypeDef,
    Bid_ModelTypeDef,
    CatalogLot_ModelTypeDef,
    LotItemMediaFile_ModelTypeDef,
    LotMetal_ModelTypeDef,
    ListingBrowserParams,
    LotsPaginatedList_TypeDef,
    LotsRunningTimedStaggeredList_TypeDef,
    PaginatedList_TypeDef,
    RunningCatalogLot_ModelTypeDef,
    WatchlistRunningLot_ModelTypeDef
} from "../type_defs/model";
import ApiDtoConvertors from "./ApiDtoConvertors";
import {LotDetails} from "../webcast/data/data_types_definitions";
import {WebcastAuctionConnectData_TypeDef} from "../webcast";


const _logger = TaggedLogger.get('AppRemoteDataHandler');


export class AppRemoteDataHandler {

    private readonly _frontendApi:FrontendApiAdapter;
    public readonly onAuthenticationStateChanged: OnHandlerFunction<boolean>;
    public readonly onResponseSuccess: OnHandlerFunction<{ headers: {} }>;

    constructor(siteConfig:{ siteId:number, frontendApiBaseUrl:string }) {
        this._frontendApi = new FrontendApiAdapter({
            siteId: siteConfig.siteId,
            frontendApiBaseUrl: siteConfig.frontendApiBaseUrl,
        });

        this.onAuthenticationStateChanged = this._frontendApi.onAuthenticationStateChanged;
        this.onResponseSuccess = this._frontendApi.onResponseSuccess;
    }

    get _frontendApi_do_not_use():FrontendApiAdapter {
        return this._frontendApi;
    }


    setAndAsyncRemoteVerifyBidderToken(setBidderToken:string|null):Promise<boolean|null> {
        return this._frontendApi.asyncRemoteVerifyBidderToken(setBidderToken);
    }



    async submitAcceptSaleTerms(auctionId: number, expectedState: string, isNotificationSmsAuctionTimelineEnabled: boolean):Promise<{
        currentState: string
    }> {
        try {
            const response = await this._frontendApi.putAuctionBiddingWarrant(auctionId, expectedState, isNotificationSmsAuctionTimelineEnabled);
            return { currentState: response.data.current_state };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


    async submitPlaceBid(auctionId: number, lotId:number, maxBidAmount:number, raiseBid:boolean) {
        _logger.debug('.submitPlaceBid: lot:', lotId, ' amount:', maxBidAmount);
        try {
            await this._frontendApi.postAuctionLotBidOnline(auctionId, lotId, maxBidAmount, raiseBid);
            return {};
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


    async submitLotInterest(auctionId: number, lotId: number, setIsWatched:boolean) {
        _logger.debug('.submitLotInterest: lot:', lotId, ' setIsWatched:', setIsWatched);
        try {
            const response = await this._frontendApi.postAuctionLotInterest(auctionId, lotId, setIsWatched);
            return response.data;
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }




    async fetchAuctionsList(params?:ListingBrowserParams & { completesAt?: -1|1 }):Promise<PaginatedList_TypeDef<Auction_ModelTypeDef>> {
        try {
            const apiParams:ApiRequestParams_auctionsList = {
                page_number: params?.pageNumber,
                page_size: params?.pageSize,
                details_level: params?.entityDetailsLevel || ApiResourceDetailsLevelsEnum.minimal,
            };
            if (params?.completesAt) {
                apiParams.completes_at = params.completesAt;
            }

            const axiosResponse = await this._frontendApi.getAuctions(apiParams);

            return {
                list: axiosResponse.data.data.map((dto) => ApiDtoConvertors.convertAuction(dto, { _detailsLevel: apiParams.details_level })),
                totalCount: axiosResponse.data.total_count,
                pageNumber: axiosResponse.data.page_number,
                pageSize: axiosResponse.data.page_size,
                pagesCount: axiosResponse.data.pages_count,
            };

        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }

    async fetchAuction(auctionId:number, detailsLevel?:ApiResourceDetailsLevelsEnum):Promise<Auction_ModelTypeDef> {
        try {
            const axiosResponse = await this._frontendApi.getAuctionDetails(auctionId, { details_level: detailsLevel });
            return ApiDtoConvertors.convertAuction(axiosResponse.data, { _detailsLevel: detailsLevel } );
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


    async submitAuctionWebcastEnter(auctionId:number, viewerId:number = null):Promise<{ connectData:WebcastAuctionConnectData_TypeDef }> {
        try {
            const axiosResponse = await this._frontendApi.postAuctionWebcastEnter(auctionId, viewerId);
            return {
                connectData: axiosResponse.data?.connect_data
            };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


    async fetchAuctionLotsListBrowsable<T_ModelLot extends CatalogLot_ModelTypeDef, T_ApiLot extends ApiResource_LotMetal>(auctionId:number, params?:ListingBrowserParams):Promise<PaginatedList_TypeDef<T_ModelLot>> {
        try {
            const apiParams:ApiRequestParams_lotsListBrowse = {
                page_number: params?.pageNumber,
                page_size: params?.pageSize,
                sorting_criteria: params?.sortingCriteria,
                filters: params?.filters,
                details_level: params?.entityDetailsLevel,
            };

            const axiosResponse = await this._frontendApi.getAuctionLots<T_ApiLot>(auctionId, apiParams);
            const responseData = axiosResponse.data;

            return {
                list: responseData.data.map((dto) => ApiDtoConvertors.convertLot<T_ApiLot, T_ModelLot>(dto, { _detailsLevel: apiParams.details_level })),
                totalCount: responseData.total_count,
                pageNumber: responseData.page_number,
                pageSize: responseData.page_size,
                pagesCount: responseData.pages_count,
                sortingCriteria: responseData.extra.sorting_criteria,
                filters: responseData.extra.filters,
            };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }




    async fetchAuctionLotsRunningTimedStaggeredPaginatedList(auctionId:number, refTimestamp?:number|null, cursorTimestamp?:number):Promise<LotsRunningTimedStaggeredList_TypeDef> {
        try {

            const axiosResponse = await this._frontendApi.getAuctionRunningLotsTimedStaggered(auctionId, refTimestamp, cursorTimestamp);
            const responseData = axiosResponse.data;

            return {
                list: responseData.data.map((dto) => ApiDtoConvertors.convertLot<ApiResource_RunningTimedLot, RunningCatalogLot_ModelTypeDef>(dto)),
                totalCount: responseData.total_count,
                windowCount: responseData.window_count,
                refTimestamp: responseData.ref_timestamp,
                cursorTimestamp: responseData.cursor_timestamp,
                nextCursorTimestamp: responseData.next_cursor_timestamp,
            };

        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }



    async fetchAuctionWebcastLots(auctionId:number):Promise<Array<LotDetails>> {
        const fetchPage = async (pageNo:number) => {
            const axiosResponse = await this._frontendApi.getAuctionWebcastLots(auctionId, { page_number: pageNo });
            return axiosResponse.data;
        }

        try {
            let pageNo = 1;
            const responseData = await fetchPage(pageNo);
            let responseDataLots = responseData.data;

            while (pageNo < responseData.pages_count) {
                pageNo += 1;
                const responseData = await fetchPage(pageNo);
                // merge the lots arrays
                responseDataLots = responseDataLots.concat(responseData.data);
            }

            return responseDataLots.map((apiLot) => ({
                id: apiLot.id,

                number: apiLot.number,

                name: apiLot.name,
                description: apiLot.description_html,

                images: apiLot.images.map((apiLotImage) => ({
                    smUrl: apiLotImage.url_thumb,
                    mdUrl: apiLotImage.url_thumb,
                    fsUrl: apiLotImage.url,
                })),

                defaultImageSmUrl: apiLot.images[0].url_thumb,

                videoUrl: apiLot.video_url
            }));
        }
        catch (ex) {
            handleAxiosError(ex)
        }
    }

    async fetchWatchlistLotsListBrowsable(params:{ pageNumber:number, refTimestamp?:number }):Promise<LotsPaginatedList_TypeDef<WatchlistRunningLot_ModelTypeDef>> {
        try {
            const apiParams:ApiRequestParams_pagination & { ref_timestamp?: number } = {
                page_number: params?.pageNumber || 1,
                page_size: 36, // default 36 items
                ref_timestamp: params?.refTimestamp,
            };

            const axiosResponse = await this._frontendApi.getWatchlistLotsRunning(apiParams);
            const responseData = axiosResponse.data;

            return {
                list: responseData.data.map((dto) => {
                    const lot = ApiDtoConvertors.convertLot<ApiResource_WatchlistRunningLot, WatchlistRunningLot_ModelTypeDef>(dto);
                    lot.auction = ApiDtoConvertors.convertAuction(dto.auction);
                    return lot;
                }),
                totalCount: responseData.total_count,
                pageNumber: responseData.page_number,
                pageSize: responseData.page_size,
                pagesCount: responseData.pages_count,
            };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


    async fetchSearchLotsListBrowsable(params:ListingBrowserParams):Promise<LotsPaginatedList_TypeDef<CatalogLot_ModelTypeDef>> {
        try {
            const apiParams:ApiRequestParams_lotsSearch = {
                page_number: params?.pageNumber || 1,
                filters: params?.filters,
                sorting_criteria: params?.sortingCriteria,
            };

            const axiosResponse = await this._frontendApi.getSearchLots(apiParams);
            const responseData = axiosResponse.data;

            return {
                list: responseData.data.map((dto) => {
                    const lot = ApiDtoConvertors.convertLot<ApiResource_SiteSearchLot, CatalogLot_ModelTypeDef>(dto);
                    lot.auction = ApiDtoConvertors.convertAuction(dto.auction);
                    return lot;
                }),
                totalCount: responseData.total_count,
                pageNumber: responseData.page_number,
                pageSize: responseData.page_size,
                pagesCount: responseData.pages_count,
            };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }

    async fetchAuctionLot<T_ModelLot extends LotMetal_ModelTypeDef, T_ApiLot extends ApiResource_LotMetal>(auctionId:number, lotId:number, detailsLevel:ApiResourceDetailsLevelsEnum = ApiResourceDetailsLevelsEnum.metal):Promise<T_ModelLot> {
        try {
            const axiosResponse = await this._frontendApi.getAuctionLot<T_ApiLot>(auctionId, lotId, detailsLevel);
            const responseData = axiosResponse.data;
            return ApiDtoConvertors.convertLot<T_ApiLot, T_ModelLot>(responseData, { _class: responseData._class });
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }



    async fetchAuctionLotItemMediaFilesList(auctionId:number, lotId:number):Promise<PaginatedList_TypeDef<LotItemMediaFile_ModelTypeDef>> {
        try {
            const response = await this._frontendApi.getAuctionLotItemMediaFiles(auctionId, lotId);
            const responseData = response.data;

            return {
                list: responseData.data.map((dto) => ApiDtoConvertors.convertItemMediaFile(dto, { auctionId: auctionId, lotId: lotId })),
                totalCount: responseData.data.length,
                pageNumber: 1,
                pagesCount: 1,
                pageSize: 100,
            };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


    async fetchAuctionLotBidsList(auctionId:number, lotId:number):Promise<PaginatedList_TypeDef<Bid_ModelTypeDef>> {
        try {
            const response = await this._frontendApi.getAuctionLotBids(auctionId, lotId);
            const responseData = response.data;

            return {
                list: responseData.data?.map((dto) => ApiDtoConvertors.convertBid(dto, responseData.auction_timezone_name)),
                totalCount: responseData.total_count,
                pageNumber: 1,
                pagesCount: 1,
                pageSize: 500, // default server side
            };
        }
        catch (ex) {
            handleAxiosError(ex);
        }
    }


}




/**
 * @throws AxiosError
 */
function handleAxiosError(ex):never {
    _logger.warn('AxiosError: ', ex);

    if (ex.response) {
        // status is 4xx or 5xx
        throw ex;
    }
    else if (ex.request) {
        // no response received from server (usually timeout)
        throw new Error('Request timed out');
    }
    else {
        throw ex; // any other error, like error setting up the request
    }
}
